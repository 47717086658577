import React from "react";
import { Link } from "react-router-dom";
import globalStyles from "../assets/styles";

class NameTag extends React.Component {
  render() {
    return (
      <Link to="/">
        <div style={styles.box}>
          <h1 style={styles.text}>Nick Marcuzzo</h1>
        </div>
      </Link>
    );
  }
}

const styles = {
  box: {
    position: "fixed",
    left: "2.5em",
    bottom: "2.5em",
    padding: "15px",
    background: globalStyles.backgroundColor,
    borderRadius: globalStyles.borderRadius,
    color: "white",
  },
  text: {
    margin: "5px",
  },
};

export default NameTag;
