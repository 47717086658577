import React from "react";
import Form from "react-bootstrap/Form";
import globalStyles from "../assets/styles";

class Login extends React.Component {
  render() {
    return (
      <div style={styles.container}>
        <Form.Group controlId="formBasicText">
          <Form.Label>Username</Form.Label>
          <Form.Control placeholder="Username" />
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control placeholder="Password" />
        </Form.Group>
      </div>
    );
  }
}

const styles = {
  container: {
    background: globalStyles.backgroundColor,
    borderRadius: globalStyles.borderRadius,
    margin: "auto",
    width: "25vw",
    marginTop: "10vh",
    padding: "2rem",
    color: "white",
  },
};
export default Login;
