import React, { useEffect, useState } from "react";

import Canvas from "../helpers/Canvas";
import { loadImage } from "../helpers/ImagePreloader";

import Select from "react-dropdown-select";

import planetImage from "../../../assets/spacetraders/planet.png";
import gasGiantImage from "../../../assets/spacetraders/gasgiant.png";
import moonImage from "../../../assets/spacetraders/moon.png";
import asteroidImage from "../../../assets/spacetraders/asteroid.png";
const imageList = [planetImage, gasGiantImage, moonImage, asteroidImage];

const getCenter = (array) =>
  Math.min(...array) + (Math.max(...array) - Math.min(...array)) / 2;

const locationOrder = ["GAS_GIANT", "PLANET", "ASTEROID", "MOON"];

const sortLocations = (a, b) => {
  const indexA = locationOrder.indexOf(a.type);
  const indexB = locationOrder.indexOf(b.type);
  if (indexA === indexB) return 0;

  return indexA < indexB ? -1 : 1;
  // if (indexA < indexB)
};

const mapBuffer = 69;

const getBodySize = (bodyType) => {
  switch (bodyType) {
    case "PLANET":
      return 32;
    case "GAS_GIANT":
      return 40;
    case "MOON":
      return 20;
    case "ASTEROID":
      return 30;
    default:
      console.error(`Got unexpected body type: ${bodyType}`);
  }
};

const getBodyImage = (bodyType) => {
  switch (bodyType) {
    case "PLANET":
      return planetImage;
    case "GAS_GIANT":
      return gasGiantImage;
    case "MOON":
      return moonImage;
    case "ASTEROID":
      return asteroidImage;
    default:
      console.error(`Got unexpected body type: ${bodyType}`);
  }
};

const MapDraw = (system, ctx) => {
  ctx.fillStyle = "#000000";
  const locations = system.locations;
  const height = ctx.canvas.height;
  const width = ctx.canvas.width;

  const xVals = locations.map((location) => location.x);
  const yVals = locations.map((location) => location.y);

  const reqXScale =
    (width - mapBuffer) / (Math.max(...xVals) - Math.min(...xVals));
  const reqYScale =
    (height - mapBuffer) / (Math.max(...yVals) - Math.min(...yVals));
  //const scale = Math.min(reqXScale, reqYScale);

  const centerX = getCenter(xVals);
  const centerY = getCenter(yVals);

  locations.sort(sortLocations);

  locations.forEach((location) => {
    const locationX = location.x;
    const locationY = location.y;

    const pixelX = width / 2 - (centerX - locationX) * reqXScale;
    const pixelY = height / 2 - (centerY + locationY) * reqYScale;

    ctx.beginPath();
    //ctx.arc(pixelX, pixelY, getBodySize(location.type)/2, 0, 2 * Math.PI);

    let bodySize = getBodySize(location.type);
    let offset = bodySize / 2;
    let image = new Image(bodySize, bodySize);

    console.log(`Drawing: ${location.type}`);
    image.src = getBodyImage(location.type);
    image.addEventListener("load", () => {
      ctx.drawImage(
        image,
        pixelX - offset,
        pixelY - offset,
        bodySize,
        bodySize
      );
    });
    ctx.fill();
  });
};

// function SystemInfo(selectedSystem) {
//   let locationList = [];
//   for (let location of selectedSystem.locations) {
//     locationList.push(
//       <p key={location.symbol}>
//         {location.symbol} ({location.name}) [{location.x}, {location.y}]
//       </p>
//     );
//   }
//   return <div>{locationList}</div>;
// }

export default function Map(props) {
  const [selectedSystem, setSelectedSystem] = useState([]);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const systems = props.systems;

  useEffect(() => {
    Promise.all(imageList.map((image) => loadImage(image)))
      .then(() => setImagesLoaded(true))
      .catch((err) => console.log("Failed to load images", err));
  });

  return (
    <div style={props.style}>
      <div style={styles.select}>
        <Select
          valueField="symbol"
          options={systems}
          value={selectedSystem}
          labelField="name"
          clearable
          onChange={(value) => {
            setSelectedSystem(value[0] || []);
          }}
        />
      </div>
      {/* {selectedSystem.length !== 0 ? SystemInfo(selectedSystem) : null} */}
      {selectedSystem.length !== 0 && imagesLoaded ? (
        <Canvas
          draw={MapDraw.bind(null, selectedSystem)}
          animate={false}
          style={styles.map}
          width="500px"
          height="300px"
        />
      ) : null}
    </div>
  );
}

const styles = {
  select: {
    width: "60%",
  },
  map: {
    border: "3px solid brown",
    borderRadius: "3px",
    marginTop: "1em",
  },
};
