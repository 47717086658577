import React from "react";
import Select from "react-dropdown-select";
import {
  getPatternList,
  sendPatternUpdateRequest,
} from "../services/CuzzoApiService";
import { CirclePicker } from "react-color";

const _colors = [
  "#e91e63",
  "#00bcd4",
  "#4caf50",
  "#ff9800",
  "#ff0000",
  "#00ff00",
  "#0000ff",
  "#ffeb3b",
];

class PatternPicker extends React.Component {
  constructor() {
    super();
    this.state = {
      patterns: [],
      loadingPatterns: true,
      selectedPattern: [],
    };

    this.populatePatternList = this.populatePatternList.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.patternSelector = this.patternSelector.bind(this);
    this.currentPatternDisplay = this.currentPatternDisplay.bind(this);
  }

  componentDidMount() {
    this.populatePatternList();
  }

  populatePatternList() {
    getPatternList().then((patterns) => {
      this.setState({
        patterns: patterns,
        loadingPatterns: false,
        selectedPattern: [],
        color: "#0051ba",
      });
    });
  }

  submitForm() {
    let request = {
      patternId: this.state.selectedPattern.id,
    };
    if (this.state.selectedPattern.colorPicker) {
      request.color = this.state.color;
    }

    sendPatternUpdateRequest(request);
  }

  patternSelector() {
    return (
      <div>
        <div style={styles.select}>
          <h4>Pick a pattern</h4>
          <Select
            valueField="name"
            options={this.state.patterns}
            loading={this.state.loadingPatterns}
            disabled={this.state.loadingPatterns}
            values={this.state.selectedPattern}
            placeholder={"Select Pattern"}
            searchBy={"name"}
            labelField={"name"}
            searchable
            clearable
            required
            backspaceDelete
            onChange={(value) => {
              this.setState({
                selectedPattern: value[0],
              });
              console.log(value[0]);
            }}
          />
        </div>
        {this.state.selectedPattern?.colorPicker ? (
          <div style={{ ...styles.colorPickerContainer }}>
            <h4>Pick a color</h4>
            <div style={{ ...styles.colorPicker }}>
              <CirclePicker
                colors={_colors}
                color={this.state.color}
                width="100%"
                circleSpacing="10px"
                onChangeComplete={(color) => {
                  this.setState({
                    color: color.hex,
                  });
                }}
              />
            </div>
          </div>
        ) : null}
        <button
          style={styles.submitButton}
          onClick={this.submitForm}
          disabled={this.state.selectedPattern.length === 0}
        >
          Change Pattern
        </button>
      </div>
    );
  }

  currentPatternDisplay() {
    return (
      <div style={styles.currentPatternDisplay}>
        <h4>Current Pattern</h4>
        <canvas width="350px" height="100px" style={styles.canvas}></canvas>
      </div>
    );
  }

  render() {
    return (
      <div style={styles.container}>
        {this.currentPatternDisplay()}
        {this.patternSelector()}
      </div>
    );
  }
}

const styles = {
  container: {
    padding: "10px",
  },
  select: {
    padding: "5px 10px",
  },
  colorPickerContainer: {
    padding: "5px 10px",
  },
  colorPicker: {
    padding: "5px",
  },
  submitButton: {
    margin: "10px",
  },
  currentPatternDisplay: {
    padding: "10px",
  },
  canvas: {
    margin: "5px 10px",
  },
};

export default PatternPicker;
