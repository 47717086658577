import { useState } from "react";
import Select from "react-dropdown-select";

export default function TradeExplorer(props) {
  const [selectedSystem, setSelectedSystem] = useState([]);
  const systems = props.systems;

  return (
    <div style={props.style}>
      <Select
        valueField="symbol"
        options={systems}
        value={selectedSystem}
        labelField="name"
        clearable
        onChange={(value) => {
          setSelectedSystem(value[0] || []);
        }}
      />
    </div>
  );
}
