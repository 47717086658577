import React, { useRef, useEffect } from "react";

const Canvas = (props) => {
  const { draw, animate, ...rest } = props;
  const canvasRef = useRef(null);

  useEffect(
    (animate) => {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      if (animate) {
        let frameCount = 0;
        let animationFrameId;
        const render = () => {
          frameCount++;
          draw(context, frameCount);
          animationFrameId = window.requestAnimationFrame(render);
        };
        render();
        return () => {
          window.cancelAnimationFrame(animationFrameId);
        };
      } else {
        draw(context);
      }
    },
    [draw]
  );

  return <canvas ref={canvasRef} {...rest} />;
};

export default Canvas;
