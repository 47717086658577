import React from "react";
import { Link } from "react-router-dom";
import globalStyles from "../assets/styles";

class Nav extends React.Component {
  render() {
    return (
      <div style={styles.container}>
        <Link to="/ants" style={styles.link}>
          AntGame
        </Link>
        {/* <Link to="/login" style={styles.link}>
          Login
        </Link> */}
      </div>
    );
  }
}

const styles = {
  container: {
    position: "fixed",
    right: "2.5em",
    bottom: "2.5em",
  },
  link: {
    padding: "0.5em",
    marginRight: "0.5em",
    background: globalStyles.backgroundColor,
    borderRadius: globalStyles.borderRadius,
    color: "white",
    textDecoration: "none",
  },
};

export default Nav;
