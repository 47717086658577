import React from "react";
import BackgroundSlider from "react-background-slider";
import NameTag from "./NameTag";
import Login from "./Login";
import Nav from "./Nav";

// import App from "./eventApp/App";
import LedFrontend from "./ledFrontend/LedFrontend";
import SpacetradersIndex from "./spacetraders/index";
// import AntGame from "./antGame/AntGame";

import { Switch, Route } from "react-router-dom";

import image1 from "../assets/image-1.jpg";
import image2 from "../assets/image-2.jpg";
import image3 from "../assets/image-3.jpg";
import image4 from "../assets/image-4.jpg";
import image5 from "../assets/image-5.jpg";
import image6 from "../assets/image-6.jpg";

class HomePage extends React.Component {
  render() {
    let firstImagePath = process.env.PUBLIC_URL + "/firstImage.jpg";

    return (
      <div>
        <Switch>
          {/* <Route path="/app">
            <App />
          </Route> */}
          <Route path="/led">
            <LedFrontend />
          </Route>
          <Route path="/st">
            <SpacetradersIndex />
          </Route>
          <Route
            path="/ants"
            component={() => {
              window.location.replace("https://antgame.io");
            }}
          />
          <Route path="/">
            <Route path="/login">
              <Login />
            </Route>
            <Nav />
            <NameTag />
            <BackgroundSlider
              images={[
                firstImagePath,
                image1,
                image2,
                image3,
                image4,
                image5,
                image6,
              ]}
              transition={1}
            />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default HomePage;
