export default function UserInfo(props) {
  const user = props.user;
  return (
    <div style={{ ...props.style, ...styles.userInfo }}>
      {Field("Username", user.username)}
      {Field("Credits", user.credits)}
      {Field("#ofShips", user.ships.length)}
      {Field("#ofLoans", user.loans.length)}
    </div>
  );
}

function Field(label, value) {
  return (
    <p style={styles.field}>
      {label}: {value}
    </p>
  );
}

const styles = {
  userInfo: {
    border: "3px solid black",
    borderRadius: "3px",
  },
  field: {
    margin: "0.25em",
  },
};
