import axios from "axios";

const baseApiPath = "https://api.spacetraders.io";

export async function getUser(username, authToken) {
  return axios
    .get(`${baseApiPath}/users/${username}`, buildConfig(authToken))
    .then((res) => {
      return res.data.user;
    });
}

export async function getSystems(authToken) {
  return axios
    .get(`${baseApiPath}/game/systems`, buildConfig(authToken))
    .then((res) => {
      console.log("in getSystems");
      console.log(res.data.systems);

      return res.data.systems;
    });
}

export async function getSystemLocations(systemSymbol, authToken) {
  return axios
    .get(
      `${baseApiPath}/game/locations/${systemSymbol}`,
      buildConfig(authToken)
    )
    .then((res) => {
      console.log("in getSystemLocations");
      console.log(res);
    });
}

export async function getMarketPlaceInfo(locationSymbol, authToken) {
  return axios
    .get(
      `${baseApiPath}/game/locations/${locationSymbol}/marketplace`,
      buildConfig(authToken)
    )
    .then((res) => {
      console.log(res);
    });
}

function buildConfig(authToken) {
  return {
    headers: {
      Authorization: "Bearer " + authToken,
    },
  };
}
