import PatternPicker from "./components/PatternPicker";

export function LedFrontend() {
  return (
    <div style={styles.container}>
      <PatternPicker />
    </div>
  );
}

const styles = {
  container: {
    maxWidth: "400px",
    margin: "10px auto",
  },
};

export default LedFrontend;
