export const loadImage = (image) => {
  return new Promise((resolve, reject) => {
    const loadImg = new Image();
    loadImg.src = image.url;

    loadImg.onload = () => resolve(image.url);

    loadImg.onerror = (err) => reject(err);
  });
};
