import Axios from "axios";

export async function getPatternList() {
  return Axios.get("/api/led/patternlist")
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.error("Error occurred on getPatternList: " + err);
    });
}

export async function sendPatternUpdateRequest(request) {
  return Axios.post("/api/led/updatepattern", request)
    .then((res) => {
      console.log("Response is: " + res);
    })
    .catch((err) => {
      console.error("Error occurred on sendPatternUpdateRequest: " + err);
    });
}
