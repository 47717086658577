import React from "react";

import Map from "./components/Map";
import UserInfo from "./components/UserInfo";
import TradeExplorer from "./components/TradeExplorer";
import { getUser, getSystems } from "./services/SpacetradersApi";

export default class SpacetradersIndex extends React.Component {
  constructor() {
    super();
    this.state = {
      loggedIn: false,
      username: "",
      authToken: "",
      user: {},
      systems: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.logout = this.logout.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit() {
    debugger;
    getUser(this.state.username, this.state.authToken).then((user) => {
      this.setState({
        user: user,
        loggedIn: true,
      });
      this.loadSystems();
    });
  }

  loadSystems() {
    getSystems(this.state.authToken).then((systems) => {
      this.setState({
        systems: systems,
      });
    });
  }

  logout() {
    this.setState({
      loggedIn: false,
      username: "",
      authToken: "",
      systems: [],
      user: {},
    });
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit} style={styles.login}>
          <fieldset disabled={this.state.loggedIn}>
            <label>
              Username:
              <input
                type="text"
                name="username"
                value={this.state.username}
                onChange={this.handleChange}
              />
            </label>
            <label>
              AuthToken:
              <input
                type="text"
                name="authToken"
                value={this.state.authToken}
                onChange={this.handleChange}
              />
            </label>
            <input type="button" value="Login" onClick={this.handleSubmit} />
          </fieldset>
          <input type="button" value="Logout" onClick={this.logout} />
        </form>
        {this.state.loggedIn ? (
          <UserInfo user={this.state.user} style={styles.userInfo} />
        ) : null}
        {this.state.systems?.length > 0 ? (
          <Map
            systems={this.state.systems}
            style={styles.map}
            authToken={this.state.authToken}
          />
        ) : null}
        {this.state.systems?.length > 0 ? (
          <TradeExplorer
            systems={this.state.systems}
            style={styles.tradeExplorer}
          />
        ) : null}
      </div>
    );
  }
}

const styles = {
  userInfo: {
    width: "20em",
    margin: "3em",
  },
  map: {
    width: "45em",
    margin: "3em",
  },
  login: {
    margin: "1em",
  },
  tradeExplorer: {
    width: "45em",
    margin: "3em",
  },
};
